<template>
  <div class="container">
    <!-- 分页 -->
    <div class="pags">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="sizeListDef"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <div class="pags">
          <el-pagination
            @size-change="handleSizeChange"
            :page-sizes="sizeList"
            :page-size="size"
            layout="total, sizes"
            :total="total"
          >
          </el-pagination>
          <div class="myPages">
            <button :disabled="pageNum <= 1" @click="handlePrevPage">上一页</button>
            <div class="pagesdiv">{{ pageNum }}</div>
            <button :disabled="!nextPage" @click="handleNextPage">下一页</button>
          </div>
        </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    pageNum: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 0,
    },
    // nextPage: {
    //   type: Boolean,
    //   default: false,
    // },
    sizeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //   currentPage4: 1,
      //   total: 30,
      sizeListDef: [20, 50, 100, 200, 500, 1000, 2000],
      //   size: 20,
    };
  },
  created() {},
  mounted() {},
  watch: {
    //监听页数变化
    pageNum(val) {
      // 改变这个值并不会触发 handleCurrentChange
      if (typeof val === "number") {
        this.pageNum = val;
      }
    },
  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
    // // 上一页
    // handlePrevPage() {
    //   this.$emit("handlePrevPage");
    // },
    // //下一页
    // handleNextPage() {
    //   this.$emit("handleNextPage");
    // },
  },
};
</script>

<style scoped lang="scss">
.pags {
  height: 40px;
  display: flex;
  background-color: #fff;
  align-items: center;
  margin: 10px 0;
  // .el-input__inner {
  //   min-height: 28px;
  // }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  .myPages {
    height: 40px;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 14px;
    button {
      cursor: pointer;
    }
    .pagesdiv {
      margin: 0 12px;
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      background-color: #fff;
    }
    .myPagesPage {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .pageinput {
        width: 50px;
        margin: 0 4px;
        height: 30px;
        line-height: 30px;
        min-height: 30px;
        border: none;
        text-align: center;
      }
    }
  }
}
</style>
